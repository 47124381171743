import { createSelector } from 'reselect';
import { ARCHIVE_PARAMS } from '@wix/communities-blog-client-common';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { buildArchiveLinks } from '../services/archive-links';
import { getPostStatsByDate } from '../../common/store/post-stats-by-date/post-stats-by-date-selectors';

const { showNewestPostsFirst, showPostCount, monthsDisplayLimit } = ARCHIVE_PARAMS;

export const getShowNewestPostsFirst = state =>
  getAppSettingsValue({
    state,
    key: showNewestPostsFirst.appSettingsPath,
    fallback: showNewestPostsFirst.defaultValue,
  });

export const getShowPostCount = state =>
  getAppSettingsValue({
    state,
    key: showPostCount.appSettingsPath,
    fallback: showPostCount.defaultValue,
  });

export const getMonthsDisplayLimit = state =>
  getAppSettingsValue({
    state,
    key: monthsDisplayLimit.appSettingsPath,
    fallback: monthsDisplayLimit.defaultValue,
  });

export const getArchiveLinks = createSelector(
  getPostStatsByDate,
  getShowNewestPostsFirst,
  getMonthsDisplayLimit,
  (postStatsByDate, showNewestPostsFirst, monthsDisplayLimit) =>
    buildArchiveLinks(postStatsByDate, showNewestPostsFirst, monthsDisplayLimit),
);
