import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LINK_LIST_TEXT_ALIGNMENT, SECTION_BLOG_PAGE } from '@wix/communities-blog-client-common';
import { connect } from '../common/components/runtime-context';
import Link from '../common/components/link/internal-link';
import { getTextAlignment } from './selectors/link-list-selectors';
import styles from './link-list.scss';

const APP_SETTING_TO_ALIGNMENT = {
  [LINK_LIST_TEXT_ALIGNMENT.left]: 'alignLeft',
  [LINK_LIST_TEXT_ALIGNMENT.center]: 'alignCenter',
  [LINK_LIST_TEXT_ALIGNMENT.right]: 'alignRight',
};

const LinkList = ({ links, emptyState, textAlignment, sectionId }) => (
  <ul className={styles.list}>
    {links.length > 0 ? (
      links.map(({ key, path, text }) => (
        <li key={key} className={styles.item}>
          <Link
            to={path}
            sectionId={sectionId}
            addHoverClasses={false}
            className={classNames(styles.link, styles[textAlignment])}
          >
            <span className={styles.text}>{text}</span>
          </Link>
        </li>
      ))
    ) : (
      <div className={classNames(styles.message, styles[textAlignment])}>
        <span className={styles.text}>{emptyState.text}</span>
      </div>
    )}
  </ul>
);

LinkList.propTypes = {
  links: PropTypes.array,
  emptyState: PropTypes.object,
  textAlignment: PropTypes.string,
  sectionId: PropTypes.string,
};

LinkList.defaultProps = {
  links: [],
  emptyState: { text: '' },
  sectionId: SECTION_BLOG_PAGE,
};

const mapRuntimeToProps = state => ({
  textAlignment: APP_SETTING_TO_ALIGNMENT[getTextAlignment(state)],
});

export default connect(mapRuntimeToProps)(LinkList);
